import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helper/TextSerializer";
import { useLocation } from "@reach/router";
import Fader from "../helper/Fader";

const PageHero = ({ title, intro, content }) => {
  const location = useLocation().pathname;
  const isHome = location === "/";
  if (title || intro?.[0] || content) {
    return (
      <Fader>
        <section className="page-hero">
          {title && (
            <>
              {isHome && (
                <h1
                  className={`page-heading h0 ${
                    (!intro ||
                      (intro &&
                        intro._type === "textBlock" &&
                        !intro.textBlock)) &&
                    !content
                      ? "-nopad"
                      : ""
                  }`}
                >
                  {title}
                </h1>
              )}
              {!isHome && (
                <h2
                  className={`page-heading h1 ${
                    (!intro ||
                      (intro &&
                        intro._type === "textBlock" &&
                        !intro.textBlock)) &&
                    !content
                      ? "-nopad"
                      : ""
                  }`}
                >
                  {title}
                </h2>
              )}
            </>
          )}
          {(intro || content) && (
            <div className="hero-text">
              {intro && !intro._type === "textBlock" && !intro.textBlock && (
                <p className="page-hero-intro">{intro}</p>
              )}
              {intro && intro.textBlock && (
                <BlockContent
                  blocks={intro.textBlock}
                  serializers={serializers}
                />
              )}
              {content && content}
            </div>
          )}
        </section>
      </Fader>
    );
  } else {
    return <></>;
  }
};

export default PageHero;
