import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helper/TextSerializer";
import SEO from "../components/seo";
import PageHero from "../components/page-hero";

export const query = graphql`
  {
    allSanityPage(filter: { slug: { current: { eq: "terms" } } }) {
      edges {
        node {
          id
          pageSEO {
            metaDescription
            metaImage {
              asset {
                url
              }
            }
            metaTitle
          }
          title
          _rawIntro(resolveReferences: { maxDepth: 8 })
        }
      }
    }
  }
`;

class TermsPage extends React.Component {
  render() {
    const terms = this.props.data.allSanityPage.edges[0].node;
    return (
      <Layout className="page-terms-container">
        <SEO
          title={(terms.pageSEO && terms.pageSEO.metaTitle) || terms.title}
          description={terms.pageSEO && terms.pageSEO.metaDescription}
          image={terms.pageSEO && terms.pageSEO.metaImage}
        />
        <PageHero title={terms.title} />
        <div className="article-body page-body">
          <div className="page-body-inner">
            <section className="article-content">
              <BlockContent
                blocks={terms._rawIntro.textBlock}
                serializers={serializers}
              />
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

export default TermsPage;
